import request from '@/../utils/request'
// 巡检巡查
const api_name1 = '/iotechserver/WechatInspectionPatrolHistoryController'
// 访客
const api_name2 = '/iotechserver/WechatVisitorsHistoryController'

export default {
  // 巡检巡查记录
  getInspectionPatrolHistory(limit, current, ciIds, startTime, endTime) {
    return request({
      url: `${api_name1}/getInspectionPatrolHistory`,
      method: 'post',
      data: {
        limit: limit,
        current: current,
        ciIds: ciIds,
        startTime: startTime,
        endTime: endTime,
      }
    })
  },

  // 访客记录
  getWechatVisitorsHistory(limit, current, ciIds, startTime, endTime) {
    return request({
      url: `${api_name2}/getWechatVisitorsHistory`,
      method: 'post',
      data: {
        limit: limit,
        current: current,
        ciIds: ciIds,
        startTime: startTime,
        endTime: endTime,
      }
    })
  },

  // 访客出入
  getWechatCheckLog(current, limit, ciIds, wechatAdminId, startTime, endTime) {
    return request({
      url: `${api_name2}/getWechatCheckLog`,
      method: 'post',
      data: {
        current: current,
        limit: limit,
        ciIds: ciIds,
        wechatAdminId: wechatAdminId,
        startTime: startTime,
        endTime: endTime,
      }
    })
  }
}